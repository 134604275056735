@use 'sass:math';
@import '../../../../../styles/variables';

$attributesTableGridGap: 1.3rem;

.dromas-MowingGrazingAttributes-switch {
  margin: m(math.div(1,3)) m(1);
}

//.dromas-MowingGrazingAttributes-switch  .ptr-button.with-data:before {
//  content: "";
//  position: absolute;
//  top: -0.05rem;
//  right: 0;
//  border-top: 4px solid var(--accent50);
//  border-right: 4px solid var(--accent50);
//  border-left: 4px solid transparent;
//  border-bottom: 4px solid transparent;
//}

.dromas-MowingGrazingAttributes-switch  .ptr-button:not(.with-data) {
    color: rgba(var(--base100rgb), 0.5);
  }

.dromas-MowingGrazingAttributes-section:not(:last-child) {
  margin-bottom: m(math.div(1,3));
}

.dromas-MowingGrazingAttributes-section > h6 {
  padding: .15rem m(1);
  font-size: $b1;
  font-weight: bold;
  color: var(--accent70);
}

.dromas-MowingGrazingAttributes-section > p {
  padding: .15rem m(1);
  margin: 0;
  font-size: $b1;
  font-style: italic;
  color: var(--base50);
}

// table
.dromas-AttributesTable {
  width: 100%;
  font-size: $b1;
}

.dromas-AttributesTable-header {
  display: flex;
  font-weight: bold;
  color: var(--accent95);
  line-height: 1.15;
  margin: 0 m(2) 0 m(1);
  grid-gap: $attributesTableGridGap;
  padding: .25rem 0;
  border-bottom: 1px dashed var(--base30);
}

.dromas-AttributesTable-record {
  display: flex;
  line-height: 1.15;
  padding: 0.25rem m(2) 0.25rem m(1);
  color: var(--accent95);
  align-items: baseline;
  grid-gap: $attributesTableGridGap;
}

.dromas-AttributesTable-record:hover {
  background: var(--base20);
}

.dromas-AttributesTable-header > div,
.dromas-AttributesTable-record > div {
  width: 5rem;
}

.dromas-AttributesTable-header > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dromas-AttributesTable-header > div:nth-child(1),
.dromas-AttributesTable-record > div:nth-child(1), {
  width: 6rem;
}

.dromas-AttributesTable-header > div:nth-child(2),
.dromas-AttributesTable-record > div:nth-child(2), {
  width: 6.8rem;
}

.dromas-AttributesTable.mowing .dromas-AttributesTable-header > div:nth-child(4),
.dromas-AttributesTable.mowing .dromas-AttributesTable-record > div:nth-child(4),
.dromas-AttributesTable.grazing .dromas-AttributesTable-header > div:nth-child(3),
.dromas-AttributesTable.grazing .dromas-AttributesTable-record > div:nth-child(3) {
  width: 8.3rem;
}

.dromas-AttributesTable-header > div:last-child,
.dromas-AttributesTable-record > div:last-child {
  padding-right: 0;
  width: 5rem;
}