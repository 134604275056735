@use 'sass:math';
@import '../../../styles/variables';

.dromas-App{
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.dromas-AppContent {
    flex: 1 1;
    position: relative;
    z-index: 0;
    height: 100%;
}

// TODO move to timeline
.dromas-Timeline-wrapper {
    box-shadow: $layoutComponentsShadow;
    position: relative;
    z-index: 999;
    border-right: 1px solid var(--base30);
}

.ptr-center-column{
    height: 100%;
}

.ptr-layout-columns{
    display: flex;
    flex-direction: column;
}


// Maps
.ptr-map-grid .ptr-map:first-child {
    border: 0;
}

.dromas-MapSetControls {
    position: absolute;
    bottom: .4rem;
    left: .4rem;
    z-index: 3;

    display: flex;
    flex-direction: column;
    grid-gap: .35rem;
    align-items: flex-start;
}

.dromas-MapScale {
    position: relative;
    bottom: 0;
    left: 0;
}

.dromas-MapScale .ptr-MapScale-content {
    background: rgba(var(--base0rgb), .7);
    border: 2px solid var(--base60);
    border-top: 0;
}

.ptr-controls-wrapper {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100%;
}