@use 'sass:math';
@import '../../../../../styles/variables';

.dromas-AttributesList {

}

.dromas-AttributesList > div {
    //column-count: 2;
    //column-gap: m(math.div(4,3));
    position: relative;
    height: 100%;
}

//.dromas-AttributesList:after {
//    content: " ";
//    display: block;
//    position: absolute;
//    right: 20px;
//    bottom: 0;
//    left: 0;
//    height: 3rem;
//    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
//}

.dromas-AttributeSet {
    margin: m(math.div(1,3)) 0 m(math.div(2,3));
}

.dromas-AttributeSet:first-child {
    margin: 0 0 m(math.div(2,3));
}


.dromas-AttributeSet > h5 {
    padding: .15rem m(1);
    margin: 0;
    color: var(--accent95);
}

.dromas-AttributeRecord {
    display: flex;
    line-height: 1.15;
    padding: .25rem m(1);
    color: var(--accent95);
    align-items: baseline;
    font-size: $b1;
}

.dromas-AttributeRecord.is-active {
    background: var(--accent15);
}

.dromas-AttributeRecord:hover {
    background: var(--base20);
}

.dromas-AttributeRecord-name {
    flex: 1;
}

.dromas-AttributeRecord-value {
    font-weight: bold;
    text-align: right;
    display: flex;
    align-items: center;
    max-width: 20rem;
}

.dromas-AttributeRecord-value.is-emphasized {
    font-size: $a1;
    color: var(--accent70);
}

.dromas-AttributeRecord-value-color {
    display: block;
    min-width: .75rem;
    height: .75rem;
    margin-right: .5rem;
    border-radius: .375rem;
}

.dromas-AttributeRecord-unit {
    font-size: $b1;
    margin-left: .25rem;
    width: 1rem;
    color: var(--base80);
}