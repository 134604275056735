@use 'sass:math';
@import '../../../../styles/variables';

.dromas-SidePanel {
    background: linear-gradient(var(--accent05), var(--accent0));
    box-shadow: $layoutComponentsShadow;
    position: relative;
    z-index: 2;
    color: var(--accent95);
    height: 100%;
}

.ptr-panel {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.ptr-panel-tab-content{
    height: calc(100% - 3rem);
    overflow-y: auto;
}

.ptr-panel-tabs {
    display: flex;
    width: 100%;
    justify-content: space-around;
    border-bottom: 1px dashed var(--base30);
    height: 3rem;


    .ptr-button {
        background-color: transparent;
        width: 100%;
        border-radius: 0;
        display: flex;
        justify-content: center;
        transition: border 0s;
    }

    .ptr-button.active {
        border-bottom: 3px solid var(--accent65);
    }

    .ptr-button:focus::after{
        box-shadow: none;
        border-radius: 0;
    }


    .ptr-button > .ptr-button-caption {
        line-height: 1.5rem;
        padding: 0.65rem 1.5rem;
        color: rgba(var(--base100rgb), .5);
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        letter-spacing: .5px;
    }

    .ptr-button.active > .ptr-button-caption {
        color: var(--accent70);
    }
}