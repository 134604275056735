@import '../../../styles/variables';

.ptr-Checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ptr-Checkbox.is-disabled {
  pointer-events: none;
  color: var(--base35);
}

.ptr-Checkbox > span {
  margin-left: .35rem;
  color: var(--base90);
}