@use 'sass:math';
@import '../../../../styles/variables';

.dromas-DpbDetail {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dromas-DpbDetail-placeholder {
    text-align: center;
    font-style: italic;
    color: var(--base50);
    margin-top: m(1);
}

.dromas-Attributes-container {
    position: relative;
    flex: 1;
    overflow-y: auto;
    padding: m(1) 0;
}