@use 'sass:math';
@import '../../../../../../styles/variables';

.dromas-ChartTooltip {
  background: var(--base90);
  display: flex;
  align-items: center;
  padding: .5rem;
  border-radius: .15rem;
  font-size: $b1;
  color: var(--base20);
}

.dromas-ChartTooltip-color {
  width: .75rem;
  height: .75rem;
  margin-right: .5rem;
}

.dromas-ChartTooltip-label {
  margin-right: .25rem;
}

.dromas-ChartTooltip-label:after {
  content: ":"
}


.dromas-ChartTooltip-value {
  font-weight: bold;
}