@use 'sass:math';
@import '../../../../../styles/variables';

@import "../../../../../../node_modules/react-image-gallery/styles/scss/image-gallery";

.dromas-dpb-photos-info{
    padding: 0 0 m(math.div(1,3));
    display: flex;

    .ptr-checkbox-container{
        margin-left: 2rem;
    }

    label{
        cursor: pointer;
        color: var(--accent95);
    }
}

.dromas-dpb-photos{
    border-top: 1px dashed var(--base20);
    background-color: transparent;
    padding: m(math.div(2,3)) m(math.div(2,3)) m(math.div(2,3)) m(1);
}

// 
// Override galery style
// 
.image-gallery-thumbnail {
    cursor: pointer;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
    border: 3px solid var(--accent55);
}

.image-gallery-left-nav[disabled], .image-gallery-right-nav[disabled] {
    opacity: 0.2;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 3rem;
}

@media (hover: hover) and (pointer: fine) {
    .image-gallery-icon:hover {
        color: var(--accent55);
    }
}

.image-gallery-slide .image-gallery-description{
    bottom: 20px;
    background: rgba(var(--base0rgb), .7);
    font-size: $b1;
    padding: 10px 15px;
}

image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
    height: 18rem;
}
  