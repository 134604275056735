@use 'sass:math';
@import '../../../../styles/variables';

.dromas-CaseAbout {
    padding: $sidePanelPadding;
    overflow-y: auto;
}

.dromas-CaseAbout h4 {
    margin: 0 0 .5rem;
    color: var(--accent95);
}

.dromas-CaseAbout h5 {
    color: var(--accent95);
    font-style: italic;
    //font-size: $b1;
}

.dromas-CaseAbout p {
    color: $baseTextColor;
    //font-size: $b1;
}

.dromas-CaseAbout ul {
    margin: 0 m(math.div(2,3)) m(math.div(2,3));
    //font-size: $b1;
}