@use 'sass:math';
@import '../../../../../styles/variables';

.dromas-Charts {
  padding: m(1);
  overflow-x: hidden;
}

.dromas-Charts p {
  font-size: $b1;
  color: var(--base60);
  text-align: center;
}

.dromas-Charts p > em {
  color: var(--base70);
  font-weight: bold;
}

.dromas-Charts h4 {
  margin: 0;
  color: var(--accent95);
  text-align: center;
}

.dromas-Charts h5 {
  margin: 0;
  font-size: $b1;
  color: var(--accent95);
  text-align: center;
}

.dromas-Charts-section {
  display: flex;
  width: 100%;
  //grid-gap: 2rem;
  margin: m(2/3) 0 m(1);
}

.dromas-Charts-section > div {
  flex: 1;
}

.dromas-ChartWrapper {
  height: 12rem;
}

.dromas-Charts-section.bar-charts .dromas-ChartWrapper {
  width: 12rem;
  position: relative;
  margin: 0 auto;
}