@import '../../../../styles/variables';

.dromas-Timeline {
  position: relative;
  background: var(--base10);
  min-height: 6rem;
  max-width: 100%;
  z-index: 4;
  border-top: 1px solid var(--base0);

}

.dromas-Timeline.disabled {
  border-top: 1px solid var(--base95);
}
