@use 'sass:math';
@import '../../../../../../styles/variables';

.dromas-CaseStyle-legend {
    font-size: $b1;
}

// Categories
.dromas-LegendCategories:not(:last-child) {
    margin-bottom: m(math.div(1,3));
}

.dromas-LegendCategory {
    display: flex;
    align-items: center;
    grid-gap: .35rem;
}

.dromas-LegendCategory-symbol {
    width: 1.5rem;
    height: .85rem;
    border: 1px solid;
}

.dromas-LegendCategory-symbol.line {
    width: 1.5rem;
    border: 0;
}

.dromas-LegendCategory-label {
    color: var(--base80);
}