@use 'sass:math';
@import '../../../../../styles/variables';

.dromas-CaseStyle {
    padding: $sidePanelPadding;
    background: rgba(var(--accent50rgb), .18);
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dromas-CaseStyle-label {
    font-size: $b1;
    color: var(--accent70);
}

.dromas-CaseStyle-switch {
    margin: .1rem 0 .5rem;
    flex: 1;
    display: flex;
}

.dromas-CaseStyle-legend {
    font-size: $b1;
}