@import '../../../../../styles/variables';

.dromas-MapAttribution {
  z-index: 1;
  font-size: $a0;
  color: var(--base80);
  background: rgba(var(--base0rgb), .7);
  padding: .1rem .35rem;
  margin-left: .05rem;
}

.dromas-MapAttribution a {
  color: var(--base80);
}