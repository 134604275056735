@use 'sass:math';
@import '../../../../../styles/variables';

// current
.dromas-CaseSelect-current {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--accent05);
  display: flex;
  box-shadow: $layoutComponentsShadow;

}
.dromas-CaseSelect-current-placeholder {
  padding-left: .7rem;
  color: var(--accent20);
  font-style: italic;
}
.dromas-CaseSelect-current-value {
  display: flex;
  align-items: center;
  padding: .75rem 1.5rem;
}

.dromas-CaseSelect-current-value > .dromas-Logo {
  max-height: 1.309rem;
  width: 7rem;
}

.dromas-CaseSelect-current-value > .dromas-Logo path {
  fill: var(--accent50);
}

.dromas-CaseSelect-current-value > h2 {
  color: var(--accent95);
  margin: 0 0 0 1.5rem;
  padding-left: 1.5rem;
  font-size: $b2;
  flex: 1;
  white-space: nowrap;
  border-left: 1px solid $separatorColor;
}

.dromas-CaseSelect-current .ptr-panther-select-current-icon {
  display: flex;
  align-items: center;
  padding: 0 .5rem;
}
.dromas-CaseSelect-current .ptr-panther-select-current-icon svg {
  width: 1.25rem;
  height: 1.25rem;
}
.dromas-CaseSelect-current .ptr-panther-select-current-icon svg .line {
  fill: var(--accent50);
}

// content
.ptr-panther-select-list.dromas-CaseSelect-list {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  padding: 0;
}
.dromas-CaseSelect-list.ptr-panther-select-list > div > div {
  height: 100%;
  transition: transform .3s ease-in-out;
}
.dromas-CaseSelect-content {
  height: 100%;
  background: linear-gradient(var(--accent10), var(--accent0));
  background-size: auto 200%;
  background-position: 0 0;
  transition: background-position .3s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.dromas-CaseSelect.open .dromas-CaseSelect-content {
  background-position: 0 100%;
}

// Title
.dromas-Title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: m(math.div(5,3)) m(1);
  justify-content: center;
  align-items: center;
  grid-gap: m(math.div(1,3));
}

.dromas-Title > .dromas-Logo {
  height: 4rem;
  width: 20rem;
  flex-shrink: 0;
}

.dromas-Title > .dromas-Logo > path {
  fill: var(--accent50);
}

.dromas-Title h1 {
  margin: 0;
  font-weight: normal;
  font-size: $a2;
  letter-spacing: 1px;
  color: var(--accent95);
  line-height: 1.25;
}

// Cards
.dromas-CaseCards {
  display: flex;
  justify-content: center;
  margin-bottom: m(math.div(7,3));
  border-bottom: .05rem solid var(--base40);
  border-top: .05rem solid var(--base40);
}

.dromas-CaseCard-wrapper {
  flex: 1;
  cursor: pointer;
  max-width: 50rem;
}

.dromas-CaseCard-wrapper:not(:last-child) {
  border-right: .05rem solid var(--base40);
}

.dromas-CaseCard {
  position: relative;
  background: var(--accent95);
  height: 20rem;
}

.dromas-CaseCard-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.dromas-CaseCard-title {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: m(2) m(1) m(math.div(2,3));
  background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.4), rgba(0,0,0,.6), rgba(0,0,0,.8));
}

.dromas-CaseCard-wrapper:hover .dromas-CaseCard-title {
  padding: m(3) m(1) m(math.div(2,3));
  background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.5), rgba(0,0,0,.7), rgba(0,0,0,.9));
}

.dromas-CaseCard-title > h2 {
  margin: 0;
  color: var(--accent95)
}

.dromas-CaseCard-title > span {
  font-size: $a1;
  color: var(--accent70);
  flex: 1;
}

// About
.dromas-About {
  padding: 0 m(1) m(2);
  flex: 1;
}

.dromas-About > div {
  margin: 0 auto;
  max-width: 50rem;
}

.dromas-About p {
  color: $baseTextColor;
}

.dromas-About a {
  color: $baseTextColor;
}

// About
.dromas-Footer {
  padding: m(1);
}

.dromas-Footer > div {
  margin: 0 auto;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.dromas-Footer-logos {
  margin-bottom: m(math.div(2,3));
  display: flex;
  align-items: center;
  grid-gap: m(math.div(4,3));
}

.dromas-Footer p {
  color: var(--base45);
  font-size: $b1;
}

.dromas-Footer-logo path {
  fill: var(--base35);
}

.dromas-Footer-logos a:hover .dromas-Footer-logo path {
  fill: var(--base55);
}